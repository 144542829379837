// External libraries
import * as React from "react";
import { useLocalization } from "@shared-ui/localization-context";

// UITK
import { UitkSecondaryButton } from "@egds/react-core/button";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

// Components
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { HotelSearchLinkBuilder } from "components/utility/HotelSearchLinkBuilder/HotelSearchLinkBuilder";
import { isVariantEnabled } from "components/utility/experiment";
import { useHotelsContext } from "components/flexComponents/Hotels/components/HotelsContext";

// Types
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";

interface ButtonTrackedProps {
  ["data-testid"]: string;
  moduleName: string;
  rfrr: string;
  rel: string | undefined;
  href: string | undefined;
  onClick?: () => void;
  linkName: string;
}
const ButtonTracked = FlexClickTracker(UitkSecondaryButton);

export const SeeAllButton: React.FC<{}> = () => {
  const { formatText } = useLocalization();
  const {
    model: { showMoreStrategy, seeAllLink, seeAllType, locationName, view: moduleView, name: moduleName },
    context,
  } = useHotelsContext();

  if (showMoreStrategy !== "button" && showMoreStrategy !== "affinityMessage") {
    return null;
  }

  /* istanbul ignore next */
  const scrollTo = (): void => {
    const wizardElement = document.getElementById("wizard-hotel-pwa-1");

    if (wizardElement) {
      wizardElement.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      wizardElement.focus();
    }
  };

  const weGotMoreMessage = formatText("hotels.seeAllAvailableProperties", locationName);

  let btnTrackedProps: ButtonTrackedProps = {
    "data-testid": "hotels-cta-button",
    moduleName,
    rfrr: seeAllType,
    rel: "nofollow",
    href: buildLinkToSRP(context) || new HotelSearchLinkBuilder(seeAllLink).build(context),
    linkName: seeAllLink,
  };

  const buttonText = formatText("hotels.viewAllProperties");

  if (moduleView === "affinity") {
    btnTrackedProps = {
      ...btnTrackedProps,
      rel: undefined,
      href: undefined,
      onClick: scrollTo,
      linkName: "Call to action click event in hotels module",
    };
  }

  return (
    <UitkSpacing margin={{ block: "four" }}>
      <UitkLayoutFlex alignItems="center" direction="column" justifyContent="center">
        <UitkSpacing margin={{ blockstart: "three" }}>
          <UitkText size={300} weight="bold" theme="emphasis">
            {formatText("hotels.lookingFor")}
          </UitkText>
        </UitkSpacing>
        {weGotMoreMessage && <UitkText size={300}>{weGotMoreMessage}</UitkText>}

        <UitkSpacing margin={{ blockstart: "three", blockend: "three" }}>
          <ButtonTracked {...btnTrackedProps}>{buttonText}</ButtonTracked>
        </UitkSpacing>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

/**
 * Manually builds a link to SRP with +30 days ahead and respective filters
 * Only available for SRP Lite Experiment.
 *
 * @param context
 */
const buildLinkToSRP = (context: ExtendedContextStore) => {
  if (!isVariantEnabled(context, "Blossom_SRP_Lite_V2")) {
    return null;
  }

  const link = new HotelSearchLinkBuilder()
    .withDaysInFuture()
    .withStayLength()
    .withExpandForm()
    .withRegionId(context.searchContext?.location?.id);

  // Add filters to SRP link
  const filters: any = context.searchContext?.affinity?.hsrFilters;
  if (filters) {
    Object.keys(filters).forEach((key) => {
      link.withFilter(key, filters[key]);
    });
  }

  return link.build(context);
};

import * as React from "react";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkCard } from "@egds/react-core/cards";
import { UitkLayoutGrid, UitkLayoutGridItem } from "@egds/react-core/layout-grid";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkBlockSkeleton, UitkLineSkeleton } from "@egds/react-core/skeleton";
import { liveAnnounce } from "@egds/react-core/utils";
import { useLocalization } from "@shared-ui/localization-context";

const results: string[] = new Array(10).fill(null).map((_, id) => `hotel-placeholder-${id}`);

export const PlaceholderResults: React.FC = () => {
  const { formatText } = useLocalization();

  liveAnnounce(formatText("propertyfilters.results.loading"));

  return (
    <div data-testid="loading-hotels">
      {results.map((key) => {
        return (
          <UitkSpacing margin={{ block: "four", inline: "four" }} key={key}>
            <UitkCard>
              <UitkLayoutGrid columns={3} dataTestId="placeholder-card">
                <UitkLayoutGridItem colSpan={1}>
                  <UitkFigure
                    ratio={UitkFigureAspectRatioType.R16_9}
                    className="uitk-card-roundcorner-top-left uitk-card-roundcorner-bottom-left"
                  >
                    <UitkBlockSkeleton />
                  </UitkFigure>
                </UitkLayoutGridItem>
                <UitkLayoutGridItem colSpan={2}>
                  <UitkSpacing padding={{ block: "two", inline: "two" }}>
                    <div>
                      <UitkSpacing margin={{ blockend: "two" }}>
                        <UitkLineSkeleton animation type="primary" />
                      </UitkSpacing>
                      <UitkSpacing margin={{ blockend: "two" }}>
                        <UitkLineSkeleton animation type="secondary" />
                      </UitkSpacing>
                    </div>
                  </UitkSpacing>
                </UitkLayoutGridItem>
              </UitkLayoutGrid>
            </UitkCard>
          </UitkSpacing>
        );
      })}
    </div>
  );
};

export default PlaceholderResults;

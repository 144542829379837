import * as React from "react";
import { UitkCard } from "@egds/react-core/cards";
import { UitkText, UitkHeading } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkSpacing } from "@egds/react-core/spacing";
import { MessageResult } from "__generated__/typedefs";

export const MessagingCard = ({ title, subtitle }: MessageResult) => {
  /* istanbul ignore next */
  const iconDescription = title?.icon?.description;
  /* istanbul ignore next */
  const iconName = title?.icon?.id;
  /* istanbul ignore next */
  const heading = title?.text!;

  return (
    <UitkSpacing margin={{ block: "three" }}>
      <div role="alert">
        <UitkCard padded>
          <UitkLayoutFlex alignItems="center" space="three">
            {iconName && (
              <UitkLayoutFlexItem>
                <UitkIcon
                  id="info-icon"
                  spotlight={{ theme: "info", background: true }}
                  description={iconDescription}
                  name={iconName}
                  size={UitkIconSize.MEDIUM}
                />
              </UitkLayoutFlexItem>
            )}
            <UitkLayoutFlexItem>
              <div>
                <UitkHeading tag="h3" size={7}>
                  {heading}
                </UitkHeading>
                {subtitle && <UitkText size={300}>{subtitle.text}</UitkText>}
              </div>
            </UitkLayoutFlexItem>
          </UitkLayoutFlex>
        </UitkCard>
      </div>
    </UitkSpacing>
  );
};

export default MessagingCard;
